import React from "react";
import { Link } from "gatsby";

export default function Pagination(props) {
  return (
    <div className="pagination">
      <div>
        {!props.isFirst && (
          <Link className="pagination-link" to={props.prevPage} rel="prev">
            ← Previous
          </Link>
        )}
      </div>
      <div>
        <p className="current-page-text">{`Page ${props.currentPage}/${props.numPages}`}</p>
      </div>
      <div>
        {!props.isLast && (
          <Link className="pagination-link" to={props.nextPage} rel="next">
            Next →
          </Link>
        )}
      </div>
    </div>
  );
}
