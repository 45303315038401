import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const { format } = require("date-fns");

export default function Archive(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const myData = {};
    props.archiveData.forEach((node) => {
      if (node.publishedAt) {
        const year = format(node.publishedAt, "YYYY");
        const month = format(node.publishedAt, "MMMM");
        if (myData[year]) {
          myData[year]["total"] =
            (myData[year]["total"] ? myData[year]["total"] : 1) + 1;
          if (myData[year][month]) {
            myData[year][month].posts.push(node);
          } else {
            myData[year][month] = { posts: [node] };
          }
        } else {
          myData[year] = { [month]: { posts: [node] } };
          myData[year]["total"] =
            (myData[year]["total"] ? myData[year]["total"] : 0) + 1;
        }
      }
    });

    setData(myData);
  }, [props.archiveData]);

  return (
    <div>
      <hr />
      <h1 className="index-sidebar-header">{props.title}</h1>
      <Accordion
        preExpanded={["yearOpen"]}
        allowZeroExpanded
        allowMultipleExpanded
      >
        {Object.keys(data)
          .slice(0)
          .reverse()
          .map((year, index) => {
            let firstYear = index === 0 ? "yearOpen" : Math.random();
            return (
              <AccordionItem key={index} uuid={firstYear}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {year}
                    <span className="archive-year-total">
                      ({data[year].total})
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                {Object.keys(data[year]).map((month, index) => {
                  if (month === "total") return null;
                  else {
                    let firstMonth =
                      index === 0 && firstYear === "yearOpen"
                        ? "monthOpen"
                        : Math.random();
                    return (
                      <AccordionItemPanel key={index}>
                        <Accordion
                          allowMultipleExpanded
                          allowZeroExpanded
                          preExpanded={["monthOpen"]}
                        >
                          <AccordionItem uuid={firstMonth}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                {month}{" "}
                                <span className="archive-month-total">
                                  ({data[year][month].posts.length})
                                </span>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            {data[year][month].posts.map((post, index) => {
                              if (!post.slug) {
                                return null;
                              }
                              const dateSegment = format(
                                post.publishedAt,
                                "YYYY/MM"
                              );                              
                              return (
                                <AccordionItemPanel
                                  className="accordion-link"
                                  key={index}
                                >
                                  <Link
                                    to={`/${props.route}/${dateSegment}/${post.slug.current}`}
                                  >
                                    {post.title}
                                  </Link>
                                </AccordionItemPanel>
                              );
                            })}
                          </AccordionItem>
                        </Accordion>
                      </AccordionItemPanel>
                    );
                  }
                })}
              </AccordionItem>
            );
          })}
      </Accordion>
    </div>
  );
}
